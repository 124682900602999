/*******************************
         Site Overrides
*******************************/

.ui.modal > .header {
    display: flex;
    justify-content: space-between;
    font-size: 16px!important;
}

.modal.getHelpModal .content{
    max-height: 613px;
    overflow-y: auto;
}

.modal.getHelpModal .spanishHelpBox{
    background: #F2F2F2;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 24px;
}

.modal.getHelpModal .getHelpSubmitError{
    margin: 0.8em;
}

.ui.modal > .header.customModalHeader{
    display:flex;
    justify-content: space-between;
    align-content: center;
    font-size: 1rem;
    color: @neutral900;
    line-height: 24px;
    padding: 1rem 1.5rem;
    border-color: @neutral300;
    & > span {
        margin: 0;
    }
}

.ui.modal.revokeConsentModal{
    width: 525px;
    height: 295px;
}