@font-face {
    font-family: 'EuclidCircularA-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('EuclidCircularA-Bold'), url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-Bold.woff') format('woff');
}

@font-face {
    font-family: 'EuclidCircularA-SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('EuclidCircularA-SemiBold'), url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'EuclidCircularA-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('EuclidCircularA-Medium'), url('https://brella-platform-assets.s3.amazonaws.com/common/fonts/EuclidCircularA-Medium.woff') format('woff');
}

.clButton.ui.basic.primary.button {
    box-shadow: 0 0 0 0px #616161 inset !important;
}
