/*******************************
        Site Overrides
*******************************/

.ui.error.message,
.ui.negative.message{
    background-color: @warning50;
    color: @textColor;
    font-size: @smallerFontSize;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

.ui.info.message {
    color: #276F86;
}

.ui.warning.message {
    background-color: @linen;
    color: @neutral900;
}

.ui.message.newClaimReviewOnBehalfMessage {
    background: @voyaLightGray;
    border: 1px solid @voyaDarkBlue;
    box-shadow: unset;
}
