/*******************************
         Site Overrides
*******************************/

.ui.toggle.checkbox input:checked ~ label {
color: #333 !important;
font-weight: 600;
}

.ui.checkbox.radio input:checked ~ label:before,
.ui.checkbox.radio input:checked ~ .box:before,
.ui.checkbox.radio input:checked:focus ~ label:before {
border-color: @neutral600!important;
}

.radioNormalFontSize .ui.checkbox label {
    font-size: @fontSize;
}

/* --- APPOINTMENT --- */

.appointmentCertifyCheckboxes .ui.checkbox {
    line-height: @lineHeight!important;
    margin-bottom: 1.5em;
}

.appointmentCertifyCheckboxes .ui.checkbox input ~ label:before{
    margin-top: 3px;
}

.appointmentCertifyCheckboxes .ui.checkbox label {
    font-size: @fontSize;
}

.childEligibilityAgreementCheckbox {
    margin-bottom: 15px;
}
