/*******************************
         Site Overrides
*******************************/
/* --- MEMBER --- */

.ui.menu.member-menuContainer {
    background: white!important;
    /*box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;*/
    margin: 0;
    padding: 10px 8px;
    border-bottom: none!important;
    height: 64px;
    z-index: 102;
}

.ui.menu.member-menuContainer .member-headerLogo {
    max-width: 172px;
    margin-left: 1em;
    margin-top: 8px;
    max-height: 32px;
}

.ui.menu.member-menuContainer .menuLogoColumn {
    width: 40%;
}

.ui.menu.member-menuContainer .menuCenterColumn {
    width: 20%;
    text-align: center;
    margin: auto 0;
}