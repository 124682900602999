/*******************************
         Site Overrides
*******************************/

.ui.label.draft {
    background-color: @voyaLightGray;
    font-size: @smallFontSize;
    font-weight: normal;
    color: @neutral900;
    display: inline-flex;
        align-items: center;
}

.ui.label.draft .icon{
    color: @neutral500;
    font-size: 9px;
}

.ui.label.open,
.ui.label.under-review,
.ui.label.payment-pending,
.ui.label.approved,
.ui.label.payment-issued {
    background-color: @voyaLightGray;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.open .icon,
.ui.label.payment-pending .icon,
.ui.label.payment-issued .icon {
    color: @sunshade;
    font-size: 9px;
}

.ui.label.open .icon,
.ui.label.under-review .icon {
    color : #ff9800 !important;
    font-size: 9px;
}

.ui.label.not-in-good-order {
    background-color: @warning50;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.not-in-good-order .icon{
    color: @warning900;
    font-size: 9px;
}

.ui.label.approved .icon{
    color: @eucalyptus;
    font-size: 9px;
}

.ui.label.denied {
    background-color: @neutral200;
    font-size: @smallFontSize;
        font-weight: normal;
        color: @neutral900;
        display: inline-flex;
            align-items: center;
}

.ui.label.denied .icon{
    color: black;
    font-size: 9px;
    margin-bottom: 2px;
}

.ui.label.QLECurrentLabel {
    background-color: @fog;
    border-radius: 100px;
    color: #000000;
    font-size: @smallerFontSize;
}

.ui.label-verificationStatus {
    margin-bottom: 20px!important;
    border-radius: 4px;
    font-weight: normal!important;
    font-style: normal!important;
    padding: 4px 8px!important;
    height: 29px!important;
}

.ui.label-bkgSunshade50 {
    background: @sunshade50!important;
}

.ui.label-bkgWarning50 {
    background: @warning50!important;
}

.ui.label-rounded {
    margin-left: 4px!important;
    border-radius: 99px!important;
}

.ui.label-gray {
    background: @neutral200!important;
}
