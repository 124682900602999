/*******************************
         Site Overrides
*******************************/

.ui.readonly.input > input,
.ui.input:not(.readonly) input[readonly] {
    background: @readonlyFieldsBkg;
     border: 1px solid @neutral500!important;
}

.ui.form .disabled,
.ui.form .disabled.field{
    opacity: 1!important;
}

.ui.disabled.selection.dropdown,
.ui.disabled.input input {
    opacity: 1!important;
    background-color: #eeeeee;
    border-color: #8e8e8e;
}

.ui.disabled.selection.dropdown .text {
    color: @neutral600;
}
