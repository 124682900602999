/*******************************
         Site Overrides
*******************************/

.ui.container.stopScreens {
    width: 622px;
    background: white;
    padding: 120px 0 110px;
}

.ui.container.econsentInterstitialImageContainer {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    width: 190px;
    height: 200px;
}

.ui.container.econsentInterstitialImage {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 2;
    width: 190px;
}