/*******************************
    User Variable Overrides
*******************************/
.ui.exclusionsList.list > .item:before {
     padding-right: 0.6rem;
     margin-left: -1.25rem;
     opacity: 0.8;
     content: attr(data-bullet);
}

.ui.uploads.list {
    padding: 0px 0px 24px 2px;
}

.ui.uploads.list > .item {
    line-height: 21px;
}

.ui.uploads.list > .item:before {
    content:"\2219";
    font-size: 1.5em;
    font-family: Trebuchet MS;
    margin-left: -0.5rem;
}

.ui.ordered.list.newClaimSuccessNextSteps > .item:before {
    content: counters(ordered, ".") ". ";
	font-weight: 700;
}
