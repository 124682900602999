/*******************************
        User Overrides
*******************************/

.menuUserDropdown > .dropdown.icon {
    color: #616161;
}

.ui.dropdown input[type="text"]:focus {
  border-width: 0 0 3px 0;
  border-radius: 0;
  border-color: @focusBlue;
}

.ui.dropdown > .text {
    font-size: @smallFontSize;
}

.ui.selection.dropdown {
    border: 1px solid @neutral500;
    padding: 8px 8px!important;
    min-height: 36px;
    font-size: @smallFontSize;
    line-height: 18px;
}

.ui.readonly.selection.dropdown,
.ui.input:not(.readonly) input[readonly] {
    background: @readonlyFieldsBkg;
    border: 1px solid @readonlyFieldsBkg;
}

.ui.dropdown.error.optionWithError,
.ui.dropdown.error.optionWithError > .menu .active.item{
    background: transparent;
}

.ui.dropdown.error,
.ui.dropdown.error > .text,
.ui.dropdown.error > .default.text,
 .ui.dropdown.error.optionWithError > .menu > .item{
    color: rgba(0, 0, 0, 0.95);
}

.ui.dropdown.error.optionWithError > .menu > .item:not(.selected.active):hover {
    background: rgba(0, 0, 0, 0.03);
}

.ui.selection.active.dropdown.optionWithError .menu {
    border-color: #E0B4B4
}
