/*******************************
         Site Overrides
*******************************/

.ui.divider[class*="small"] {
  margin: 0.5rem auto !important;
}

.ui.divider[class*="medium"] {
  margin: 1.5rem auto !important;
}

.ui.divider[class*="large"] {
  margin: 2.5rem auto !important;
}