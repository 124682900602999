/*******************************
         Site Overrides
*******************************/

h1, h1.ui.header, h2, h2.ui.header {
    line-height: 130%;
}

h3, h3.ui.header, h4.ui.header, h4 {
    line-height: 150%;
}

.ui.header {
    margin: 1rem 0;
    color: @primaryHeaderColor;
}

.ui.header.econsentInterstitialHeader {
    margin-bottom: 24px;
    font-size: 24px;
}

.ui.header.memberLoginMainHeader {
    position: relative;
    max-width: 485px;
    margin-top: 0!important;

    @media not screen and (min-width: 1024px) {
        max-width: 400px;
        padding: 40px 0;
        font-size: 32px;
    }
    @media screen and (min-width: 1024px) and (max-height: 1023px) {
            font-size: 32px;
    }
    @media not screen and (min-width: 480px) {
        font-size: 20px;
        padding-top: 30px;
    }
}

.memberLoginContent > .ui.header {
    margin-top: 0;
    margin-bottom: 0.5rem;
    @media not screen and (min-width: 480px) {
        font-size: 20px;
    }
}