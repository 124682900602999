/*******************************
         Site Overrides
*******************************/

.ui.button {
    font-size: @smallFontSize !important;
    font-weight: bold !important;
}

.ui.basic.primary.button.clButton {
    border: 1px solid #616161;
    box-shadow: none !important;
    color: #616161;
}

.ui.basic.primary.button.clButton b {
    color: #616161;
}

.ui.disabled.button:not(.basic), .ui.button:disabled:not(.basic) {
    background-color: @disabledButtonBkg !important;
    color: #fff;
    opacity: 1 !important;
}

@media (max-width: 1250px) {
    .ui.primary.button.responsive-button {
        width: 100%;
    }
}

.ui.basic.primary.button.clHelpButton {
    border: 1px solid @neutral600;
    box-shadow: none !important;
    color: @neutral600 !important;
}

.ui.basic.primary.button.clHelpButton b {
    color: @neutral600;
}

.ui.basic.primary.button.unclickable {
    cursor: text;
}