

.ui.table thead th {
    font-size: 16px;
}

.ui.table.very.basic thead th {
    color: @neutral500;
    text-transform: uppercase;
    font-size: @smallerFontSize;
}

.ui.table.very.basic thead th:first-child {
    padding-left: 0!important;
}

.categoryDescription p {
    font-size: 12px;
    font-weight: 600;
}

.categoryDescription .ui.header {
    margin-bottom: 0.4rem;
}

.categoryIncrements {
    -webkit-font-smoothing:auto;
}

/* --- EMPLOYER / PROPOSAL --- */

.ui.table.monthlyCostTable {
    border: 1px solid #E6B8A7;
}

.monthlyCostTableContainer:before {
        content:"";
        display:inline-block;
        border-top: 1px solid #E6B8A7;
        border-right: 1px solid #E6B8A7;
        background: #FFEFEA;
        width: 10px;
        height: 10px;
        left: 10%;
        top: 12px;
        position: relative;
        z-index: 1;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
  }

.ui.table.monthlyCostTable table {
    border: none;
    font-size: @smallerFontSize;
}

.ui.table.monthlyCostTable td:first-child {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.ui.table.monthlyCostTable thead th {
    border: none;
    border-bottom: 1px solid #E6B8A7!important;
    background: #FFEFEA!important;
    color: @neutral900!important;
}

/* --- MEMBER / STOP SCREENS --- */

.ui.table.itemsDetailsTable {
    width: 100%;
    border: none !important;
}

.ui.table.itemsDetailsTable tr td {
    padding-right: 0;
    padding-left: 0 !important;
}

.ui.table.itemsDetailsTable:not(.unstackable) tr {
    box-shadow: none !important;
}

.ui.table.itemsDetailsTable > thead {
    font-size: 14px;
    font-weight: bold;
    border: none !important;
}

.ui.table.itemsDetailsTable > thead > tr > td:first-child {
    padding-bottom: 0;
}

.ui.table.itemsDetailsTable > tbody > tr {
    display: flex !important;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
}

.ui.table.itemsDetailsTable > tbody > tr:last-child {
    border-bottom: none;
}

.ui.table.itemsDetailsTable > tbody > tr > td {
    border-top: none !important;
}
