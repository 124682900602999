/*******************************
         Site Overrides
*******************************/

/* --- MEMBER --- */

.column.planSelectTable {
    border: 1px solid;
    border-radius: 0;
    padding: 0 !important;
}

.column.paymentMethod {
    max-height: 79.5px!important;
}

.ui.grid > .row.interstitialImageContainer {
    margin: 8px 0 24px 0;
}

.ui.grid > .row.interstitialImageContainer > .column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.grid > .row.interstitialImageContainer > .column > p {
    margin-top: auto;
    padding-top: 24px;
    text-align: center;
}

.ui.grid.interstitialOrText {
    height: 200px;
}

.ui.grid.interstitialOrText > .column.interstitialColumns {
    width: 0;
    padding: 0;
}

.ui.grid > .row.planEndYourCoverage {
    padding-bottom: 4rem;
}

.ui.grid > .column.confirmPlan {
    padding-bottom: 64px;
}

/* --- Grid Fitted --- */

.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .row > .column,
 .ui[class*="horizontally fitted"].grid > .column:not(.row),
 .ui[class*="horizontally fitted"].grid > .row > .column {
    padding-left: 0em!important;
    padding-right: 0em!important;
}

.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="fitted"].grid:not(.vertically):not(.horizontally) > .row,
 .ui[class*="vertically fitted"].grid > .column:not(.row),
 .ui[class*="vertically fitted"].grid > .row {
    padding-top: 0em!important;
    padding-bottom: 0em!important;
}

/* --- Grid Compact --- */

.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .row > .column,
.ui[class*="horizontally compact"].grid > .column:not(.row),
.ui[class*="horizontally compact"].grid > .row > .column {
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .column:not(.row),
.ui[class*="compact"].grid:not(.vertically):not(.horizontally) > .row,
.ui[class*="vertically compact"].grid > .column:not(.row),
.ui[class*="vertically compact"].grid > .row {
   padding-top: 0.5em!important;
   padding-bottom: 0.5em!important;
}

/* --- Grid.Row Fitted --- */

.ui.grid > [class*="fitted"].row:not(.vertically):not(.horizontally) > .column,
.ui.grid > [class*="horizontally fitted"].row > .column {
    padding-left: 0em!important;
    padding-right: 0em!important;
}

.ui.grid > [class*="fitted"].row:not(.vertically):not(.horizontally),
.ui.grid > [class*="vertically fitted"].row {
    padding-top: 0em!important;
    padding-bottom: 0em!important;
}


/* --- Grid.Row Compact --- */

.ui.grid > [class*="compact"].row:not(.vertically):not(.horizontally) > .column,
.ui.grid > [class*="horizontally compact"].row > .column{
    padding-left: 0.5em!important;
    padding-right: 0.5em!important;
}

.ui.grid > [class*="compact"].row:not(.vertically):not(.horizontally),
.ui.grid > [class*="vertically compact"].row {
    padding-top: 0.5em!important;
    padding-bottom: 0.5em!important;
}

.ui.grid > .row.interstitialImageContainer {
    margin: 8px 0 24px 0;
}

.ui.grid > .row.interstitialImageContainer > .column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.grid > .row.econsentInterstitialRow {
    margin-top: 160px;
}

.ui.grid > .row.econsentInterstitialRow > .column {
    padding: 0;
    width: 400px;
    height: 472px;
}