/*******************************
         Site Overrides
*******************************/

.ui.segment.lifeEvent.isSelected {
    background: @fog
}

.ui.segment.lifeEvent .lifeEventIcon {
    align-self: center;
    color: white;
    border-radius: 500em;
    line-height: 40px;
    width: 40px;
    height: 40px;
}

.ui.segment.lifeEvent .lifeEventIcon.primaryQLEIcon {
    background-color: @voyaDarkBlue;
}

.ui.segment.lifeEvent .lifeEventIcon.secondaryQLEIcon {
    background-color: @voyaDarkOrange;
}

.ui.segment.deepPurpleCard {
    border-color: @voyaPlum;
}

.ui.segment.toast {
    position: fixed;
    bottom: 60px;
    right: 10px;
    border-radius : 4px;
    z-index: 99999;
    padding: 1rem;
}

.ui.segment.toast > :first-child {
    display: flex;
}

.ui.segment.toast  .toast-content {
    padding-left:16px;
    padding-right:8px;
    flex-grow: 1;
    max-width: 330px;
}

.ui[class*="half padded"].segment {
    padding: 0.5em;
}

.ui[class*="double padded"].segment {
    padding: 2em;
}

.ui[class*="moderate padded"].segment {
    padding: 2.5em;
}

.ui.segment.mobileAppContainer {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    z-index: 9999;
    text-align: center;
    max-height: 100vh;
    padding: 0;
}

.ui.basic.segment.AOEsidebarWidgetContainer {
	background: @neutral50;
	padding: 16px;
	font-size: 12px;
	line-height: 18px;
}

.ui.loading.segment.recommendationTier {
	margin: 0;
    padding: 0;
}

.ui.loading.segment.recommendationTier:before {
	border-radius: unset !important;
}

.ui.segment.econsentInterstitialContainer {
    padding: 40px;
    text-align: center;
}

.ui.segment.noPadding {
    padding: 0;
}