/*******************************
         Site Overrides
*******************************/

html,
body {
    height: 100%!important;
    position: relative;
    overscroll-behavior-y: none;
}

.headerContainer {
    background: white;
    box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)!important;
    margin-bottom: 50px;
    padding: 18px 1em;
}

.menuUserDropdown>.text {
    color: @voyaDarkBlue;
    font-size: @fontSize!important;
    font-weight: bold;
}

.menu .menuUserDropdown.ui.dropdown .menu > .item.small .text {
    font-size: @smallFontSize;
}

.pageContent {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

a, .linkText {
    font-weight: 600;
    color: @linkColor;
}

a:hover, .linkText:hover {
    cursor: pointer;
    color: @linkHoverColor;
}

.neutral900Text {
    color: @neutral900!important;
}

.requiredField:after {
    display: inline-block;
    vertical-align: top;
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #DB2828;
}

.centerAligned {
    text-align: center;
}

.ui.segment {
    box-shadow: none !important;
    border-radius: 8px !important;
}

.colorful-border.ui.segment {
    border: 1px solid @voyaDarkBlue;
}

.hovereable.ui.segment:hover {
    border: 1px solid @focusBlue;
    box-sizing: border-box;
    border-radius: 8px;
}

.no-padding.column {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

/* --- BODY FONT HEADERS --- */
.vibranBlueHeader {
    color: @vibranBlue;
    font-weight: bold;
}

.darkOrangeHeader {
    color: @voyaDarkOrange;
    font-weight: bold;
}

.deepPurpleHeader {
    color: @voyaPlum;
    font-weight: bold;
}

/* --- FONT SIZE --- */

.small {
    font-size: @smallFontSize !important;
    line-height: 150%;
}

.smaller {
    font-size: @smallerFontSize;
    line-height: 150%;
}


/* --- FONT COLOR --- */
.vibranBlueText {
    color: @vibranBlue;
}

.neutral500Text {
    color: @neutral500!important;
}

.neutral600Text {
    color: @neutral600!important;
}

.neutral700Text {
    color: @neutral700 !important;
}

.mediumBlueText {
    color: @focusBlue;
}

.secondaryBlueText {
    color: @voyaDarkBlue !important;
}

.warningRedText {
    color: @warning900!important;
}

.sunshadeText {
    color: @sunshade!important;
}

.peach {
    color: @peach!important;
}


/* --- STEPS --- */

.steps .ui.grid .column{
    padding-left: 0!important;
    padding-right: 0!important;
    font-weight: 600;
}

.step {
    display: table;
    white-space: nowrap;
    height: auto;
    margin: '';
    line-height: 1;
    text-align: center;
}

.step > .lineContainer {
    content: '';
    display: table-cell;
    padding-top: 5px;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

.step > .lineContainer > .grey {
    height: 2px;
    background-color: @neutral300;
}

.step > .lineContainer > .completed {
    height: 2px;
    background-color: @voyaDarkBlue;
}

.step>.lineContainer>.colorLine {
    height: 2px;
    background-color: @voyaDarkBlue;
}


.step>.ui.basic.label {
    border-width: 2px!important;
    line-height: .5em!important;
}

.step>.ui.basic.grey.label {
    border-color: @neutral300;
}

.step > .ui.circular.label {
    margin-top: 8px;
    font-size: 5px;
    margin-right: 0;
}

.step > .ui.custom.label {
    background-color: @voyaDarkBlue !important;
    border-color: @voyaDarkBlue !important;
}

.step > .ui.grey.label {
    background-color: #ddd !important;
    border-color: #ddd !important;
}

.disabledStep {
    color: @neutral700;
    font-weight: normal;
}


/* --- FOOTER --- */

.footer {
    background-color: @neutral50;
    border-color: #E0E0E0;
    border-top: 1px solid #EEEEEE;
    position: fixed;
     bottom: 0;
     width: 100%;
     text-align: center;
     margin-top: 1em;
}

.footer .buttonContainer {
    padding: 1.5em;
    border-bottom: 1px solid #EEEEEE;
}

.footer .ui.text.menu {
    font-size: 12px!important;
    margin: .5em 0;
}

.footer .ui.text.menu .item {
    font-size: 12px!important;
    color: @neutral700;
}

.footer .ui.text.menu a.item {
    font-weight: 700;
    color: @neutral500;
}


/* ---- SIDEBAR MENU---- */

.sidebarItem {
    color: @neutral600;
    font-size: @smallFontSize;
    line-height: 300%;
    font-weight: bold;
}

.sidebarItem.active {
    color: @textColor!important;
}

.contentContainer {
    padding-bottom: 2em;
}

.clickable:hover {
    cursor: pointer;
}

/* --- MEMBER --- */

.loginContainer {
    padding-top: 130px;
    height: 100%;
}

.member-main-container {
	min-height: 100vh; /* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 160px; /* height of your footer */
	padding-top: 64px;
	background: @neutral50;
	overflow-x: scroll;
}

.member-header-shadow {
    box-shadow: 0px 2px 2px 2px rgba(220, 230, 229, 0.5);
    position: fixed;
    top: 64px;
    width: 100%;
    height: 1px;
    z-index: 100;
    background: white;
}

.member-headerContainer {
    margin-bottom: 50px;
    padding: 10px 8px 0 1.5em;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 102;
    height: 64px;
   /* box-shadow: 0px 4px 4px rgba(220, 230, 229, 0.5)*/
}

.member-headerContainer>.ui.grid {
    margin: 0;
}

.member-headerContainer>.ui.grid>.column {
    padding: 0.85em 0em!important;
    line-height: 1;
}

.member-headerContainer>.ui.grid>.column .rightMenu {
     display: flex;
     float: right;
 }

.member-headerContainer>.ui.grid>.column .menuUserDropdown {
     padding: 0 1.125em .875em !important;
     line-height: 1;
     align-self: flex-end;
     display: flex;
     align-items: center;
     position: relative;
     vertical-align: middle;
    flex: 0 0 auto;
 }

.memberNavigation {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    width: 220px;
    overflow-x: hidden;
    flex: 1;
    z-index: 103;
    background: white;
    padding: 16px 24px;
    border-right: 1px solid #E0E0E0;
}

.memberNavigation .item.navigationItem {
    padding: .9em 0!important;
    font-size: @smallFontSize;
}

.ui.text.menu.memberSectionMenu {
    margin: 0;
}

.ui.text.menu.memberSectionMenu .item {
    border-bottom: 4px solid transparent;
    margin-right: 2em;
}

.ui.text.menu.memberSectionMenu .item.active {
    border-bottom: 4px solid @voyaDarkBlue;
}

.googlePlacesField input {
    height: 50px;
}

.googlePlacesField .results {
    max-height: 126px;
    overflow-y: auto;
}

.bkgFog {
    background: #F4F4F5!important;
}

.bkgLinen {
    background: @linen!important;
}

.bkgNeutral50 {
    background: @neutral50!important;
}

.bkgSunshade {
    background: @sunshade!important;
}

.bkgNeutral100 {
    background-color: @neutral100!important;
}

.bkgLightGray {
    background: @voyaLightGray!important;
}

.alertContainer {
    position: fixed;
    z-index: 101;
    width: 100%;
    margin-bottom: 1em;
}

.alert {
    padding: 10px;
    text-align: center;
    font-size: @smallerFontSize;
    z-index: 101;
    position: relative;
}

.alert.warning {
    background: @linen;
    border-top: 1px solid @warning700;
    border-bottom: 1px solid @warning700;
    color: @neutral900;
}

.alert.warning a,
.alert.warning .link {
    color: @neutral900;
    cursor: pointer;
}

.text-collapsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.width-auto {
    max-width: none !important;
    width: auto !important;
}

.planChanged {
    background: @sunshade50;
}

.planChanged .icon {
    color: @sunshade;
    font-size: 9px!important;
    line-height: 9px;
}

.circularIcon60 {
    align-self: center;
    color: white;
    border-radius: 500em;
    line-height: 60px!important;
    width: 60px!important;
    height: 60px!important;
}

.sliderHeader {
    background: @neutral100;
    padding: 8px 24px;
    font-weight: bold;
    border-radius: 8px;
}

.ui.stackable.grid > .row > .column.sliderRow {
    margin-bottom: 4rem !important;
}

.break-word {
    overflow-wrap: break-word;
}

.billingAlert {
    padding: .6em;
    text-align: center;
    font-size: @smallerFontSize;
    z-index: 101;
    position: relative;
    width: calc(100% - 220px);
}

.billingAlert.due {
    background-color: @sunshade;
    color: #fff;
}

.billingAlert.past-due  {
    background-color: @warning900;
    color: #fff;
}
.nowrap {
    white-space: nowrap;
}

.documentExampleCard {
    background-color: #FAFAFA;
    max-width: 252px;
    text-align: left;
    border-radius: 16px;
}

.documentExampleCard > p {
    font-size: 14px;
    margin: 32px 16px 8px;
}

.documentExampleCard > .ui.image {
     max-height: 150px;
     margin: auto;
     padding-top: 24px;
}

.documents-main-container {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 160px; /* height of your footer */
    padding-top: 64px;
}

.error.field {
    & > label {
        color: @neutral900!important;
    }
    .ui.input > input {
        background-color: transparent!important;
    }
}

.pageContent.uploadInstructions {
    max-width: 804px;
}

.uploadInstructionsCards {
    margin-bottom: 28px;
    & > div {
        padding-left: 0!important;
        padding-right: 0px!important;

    }
}

.pageContent.uploadInstructions .proTip {
    & > div {
       padding: 0px!important;
    }
}

.confirmUploads .imageFile {
    height: 56px;
    width: 75px;
}

.confirmUploads .imageFile img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.confirmUploads .pdfFile {
    height: 56px;
    width: 75px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.confirmUploads .otherFile {
    height: 56px;
    width: 72px;
    border: 1px solid @neutral500;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.confirmUploads .notAcceptedFile {
    height: 56px;
    width: 75px;
    border: 1px solid @warning700;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.confirmUploads .notAcceptedFile i {
    color: @warning700;
}

.proTip{
    & > div {
       padding: 0px 1rem!important;
    }
}

.proTip p {
    font-size: 14px;
}

.pageContent.reviewClaimDetails {
    max-width: 779px;
    span.mediumBlueText.small{
        font-weight: 700;
    }
}

.uploadInstructions .instructionText {
    font-size: 16px;
	margin-bottom: 40px;
}

.uploadInstructions .subHeader {
    color: @voyaDarkOrange;
    font-weight: bold;
    margin: 24px 0 16px 0;
}

.uploadInstructions .checkItem {
    margin-bottom: 8px;
}

.uploadInstructions .checkItem .icon {
    margin-right: 16px;
    color: @voyaDarkOrange;
}

.uploadInstructions .description img {
    margin-right: 8px!important;
}

.uploadInstructions .description {
    font-size: 14px;
    margin: 0;
}

.uploadInstructions .cardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 16px;
}

.uploadInstructionsModalDescription {
    font-size: 16px;
    margin: 16px 0 40px;
    color: @neutral700!important;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.uploadInstructionsModalDescription img {
	margin-right: 8px !important;
}

.invalidFilesContainer {
    overflow: auto;
    height: 12%;
    margin-bottom: 1%;
}

.invalidFilesContainer > p > i.icon,
.invalidFilesContainer > p > span {
    color: @warning700;
}

.divider1_5 {
    margin-bottom: 1.5rem;
}

.conditionListTitle {
    display: inline-block;
    color: @neutral900;
}

.textLink {
    color: @neutral900;
}

.returning-user-note {
    padding: 24px;
    background-color: @voyaLightGray;
    margin: 0;
}

.mobileButtonContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.planEndYourCoverageLinkText {
	color: @neutral700 !important;
    font-size: @smallFontSize !important;
    cursor: pointer;
}

.childEligibilityAgreementBox {
    background-color: @neutral100;
    padding: 1em;
    font-size: @smallFontSize;

    p {
        font-style: italic;
        font-size: 12px;
        line-height: 18px;
    }
}

.newClaimReviewEditOption {
    margin-left: 8px;
}

.memberLoginLayout {
    display: flex;
    @media not screen and (min-width: 1024px) {
        flex-direction: column;
    }
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}

.memberLoginLoading {
    position: relative;
    pointer-events: none;
    user-select: none;
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(255, 255, 255, .5);
    }
}

.memberLoginInfoPanelLayout {
    color: @white;
    height: 100%;
    overflow: auto;
    @media not screen and (min-width: 1024px) {
        width: 100%;
        max-width: 394px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 124px;
    }
    @media screen and (min-width: 1024px) {
        padding: max(7.5vh, 20px) 60px 20px;
        width: 50%;
        max-width: 630px;
        background: url('https://brella-platform-assets.s3.amazonaws.com/voya/member/login_foreground.png') no-repeat center bottom @shadeBlue;
        background-size: 100% auto;
        color: @white;
    }
    @media screen and (min-width: 1600px) {
        max-width: 730px;
    }
    @media screen and (min-width: 1024px) and (max-height: 1023px) {
        padding-top: 60px;
        max-width: 500px;
    }
    @media screen and (min-width: 1024px) and (max-height: 750px) {
        background: @shadeBlue;
    }
}

.memberLoginBrand img {
    height: 48px;
}

.memberLoginInfoLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 0;
    @media screen and (min-width: 1024px) {
        padding-top: 32px;
        padding-bottom: 180px;
    }
    @media screen and (min-width: 1024px) and (max-height: 1023px) {
        padding-top: 24px;
    }
    @media screen and (width: 1440px) and (height: 800px) {
        padding-top: 32px;
    }
}

.memberLoginSeparator {
    display: none;
    height: 7px;
    width: 145px;
    background-color: @voyaDarkOrange;
    border-radius: 21px;
    @media not screen and (min-width: 1024px) {
        display: block;
    }
}

.memberLoginContentPanel {
    display: flex;
    flex-direction: column;
    overflow: auto;
    @media not screen and (min-width: 1024px) {
        flex: 1 1 auto;
    }
    @media screen and (min-width: 1024px) {
        flex: 1;
        height: 100vh;
    }
}

.memberLoginRegisterWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  @media screen and (min-width: 1024px) {
      padding: 16px;
  }

  @media not screen and (min-width: 1024px) {
      order: 2;
      max-width: 394px;
      display: inline-block;
      width: 100%;
      margin: auto;
  }
  & > span {
      margin-right: 18px;
  }
}

.memberLoginContent {
    width: 100%;
    max-width: 394px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    margin: auto;
    @media not screen and (min-width: 1024px) {
        order: 1;
        padding: 40px 0;
    }
}

.memberLoginContentText {
    margin-bottom: 8px;
    @media not screen and (min-width: 480px) {
        font-size: 12px!important;
        margin-bottom: 40px;
    }
}

.memberLoginFooter {
    width: 100%;
    max-width: 394px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    font-size: 12px;
    color: @neutral700;
    line-height: 1.5;
    @media not screen and (min-width: 1024px) {
        margin-top: 90px;
        order: 3;
    }
    @media not screen and (min-width: 480px) {
        flex-direction: column;
    }
}

.memberLoginFooterLinks {
    display: flex;
    line-height: 2.4;
    & > a {
        margin: 0 6px;
        color: @neutral500;
    }
}

.paymentMethodDisplay {
    display: flex;
}

.paymentMethodLabel {
  vertical-align: text-top;
}
